import React, { PropsWithChildren } from 'react';
import { PanelProps, getValueFormat, formattedValueToString, ThemeVisualizationColors } from '@grafana/data';
import { PercentPanelOptions } from 'types';
import { css, cx } from 'emotion';
import { useStyles, useTheme2 } from '@grafana/ui';
import { BiTrendingUp } from 'react-icons/bi';
import { BiTrendingDown } from 'react-icons/bi';
import { MdTrendingFlat } from 'react-icons/md';
import { GetIcon } from 'TeamcxIcons';

interface Props extends PanelProps<PercentPanelOptions> {}

const BASE_FONT_SIZE = 38;

function GetIconStyle(options: PercentPanelOptions) {
  return {
    marginRight: '10px',
    verticalAlign: 'middle',
    height: options.iconSize === undefined ? '1em' : options.iconSize,
  };
}
/*
function ReplaceVariablesByUrlSearchParam(text: string) {
  let variables = text.match(/{.*}/gim);
  let params = new URLSearchParams(window.location.search);
  if (variables != null && variables != undefined) {
    for (let i = 0; i < variables.length; i++) {
      let variable = variables[i].replace('{', '').replace('}', '').split("=");
      let variableName = variable[0];
      let defaultValue = "null";
      if(variable.length == 2)
      {
         defaultValue = variable[1];
      }
      let paramValue = params.get(variableName);
      if (paramValue != null && paramValue != undefined) {
        text = text.replace(variables[i], paramValue);
      }
      else {
        text = text.replace(variables[i], defaultValue);
      }
    }
  }
  return text;
}*/

const PrefixStyle = { marginRight: '5px' };

function SpanValue({
  className,
  fontSize,
  color,
  lineHeight,
  display,
  verticalAlign,
  children,
}: PropsWithChildren<{
  className: string;
  fontSize: string;
  color?: string;
  lineHeight?: string;
  display?: string;
  verticalAlign?: string;
}>) {
  let verticalAlignProp = 'baseline';
  let displayProp = 'block';
  if (display) displayProp = display;
  if (verticalAlign) verticalAlignProp = verticalAlign;
  return (
    <span
      className={className}
      style={{
        display: displayProp,
        fontSize: fontSize,
        color: color,
        lineHeight: lineHeight,
        whiteSpace: 'nowrap',
        verticalAlign: verticalAlignProp,
      }}
    >
      {children}
    </span>
  );
}

interface TrendDisplay {
  diff: number;
  prefix: JSX.Element;
  color?: string;
  diffFormatted: string;
  currentValueFormatted: string;
}

function prepareTrendDisplay(
  options: PercentPanelOptions,
  colors: ThemeVisualizationColors,
  baseValueSum: number,
  currentValueSum: number
): TrendDisplay {
  const stagnationTrendColor = colors.getColorByName('grey');
  const currentValueFormat = getValueFormat(options.unit)(
    currentValueSum,
    options.currentValueDecimals,
    undefined,
    undefined
  );
  const currentValueFormatted = formattedValueToString(currentValueFormat);

  if (baseValueSum === 0.0 && options.displayPercent) {
    return {
      diff: NaN,
      prefix: <></>,
      diffFormatted: 'N/A',
      currentValueFormatted: currentValueFormatted,
      color: stagnationTrendColor,
    };
  }

  const diffValue = options.displayPercent
    ? ((currentValueSum - baseValueSum) * 100) / baseValueSum
    : currentValueSum - baseValueSum;

  //Format value by unit
  const diffValueFormat = getValueFormat(options.unit)(Math.abs(diffValue), options.diffDecimals, undefined, undefined);

  const zero = getValueFormat(options.unit)(0, options.diffDecimals, undefined, undefined);

  const zeroFormatted = formattedValueToString(zero);

  //IFDUSA-1086 abs value (ex: -2% -> 2%)
  const diffValueFormatted = formattedValueToString(diffValueFormat);

  // Avoid irritation for small numbers being cut off
  const stagnation = diffValueFormatted === zeroFormatted;

  const positiveTrendColor = (options.positiveIsGood === undefined ? true : options.positiveIsGood)
    ? colors.getColorByName('green')
    : colors.getColorByName('red');

  const negativeTrendColor = (options.positiveIsGood === undefined ? true : options.positiveIsGood)
    ? colors.getColorByName('red')
    : colors.getColorByName('green');

  //const suffix = options.interpretAsTrend ? (stagnation ? ' \u25B6' : percent > 0 ? ' \u25B2' : ' \u25BC') : '';
  //const prefix = !stagnation && percent > 0 ? '+' : '';
  //IFDUSA-1086
  const prefix = stagnation
    ? () => <MdTrendingFlat style={PrefixStyle} />
    : diffValue > 0
    ? () => <BiTrendingUp style={PrefixStyle} />
    : () => <BiTrendingDown style={PrefixStyle} />;

  return {
    diff: diffValue,
    prefix: prefix(),
    color: stagnation ? stagnationTrendColor : diffValue > 0 ? positiveTrendColor : negativeTrendColor,
    diffFormatted: options.displayPercent ? diffValueFormatted + '%' : diffValueFormatted,
    currentValueFormatted: currentValueFormatted,
  };
}

export const PercentPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  const styles = useStyles(getPanelStyles);
  const theme = useTheme2();

  const currentValueFontSize = options.currentValueFontSize.includes('px')
    ? options.currentValueFontSize
    : (parseInt(options.currentValueFontSize, 10) / 100) * BASE_FONT_SIZE + 'px';

  // Get values for calculating percentage
  const currentValueSerie = data.series.find((serie) =>
    serie.fields.find((field) => field.name === options.currentValueField)
  );
  const baseValueSerie = data.series.find((serie) =>
    serie.fields.find((field) => field.name === options.baseValueField)
  );

  if (!currentValueSerie || !baseValueSerie) {
    return (
      <div
        className={cx(
          styles.wrapper,
          css`
            width: ${width}px;
            height: ${height}px;
          `
        )}
      >
        <div className={styles.textBox} style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '10px', whiteSpace: 'nowrap' }}>
            <SpanValue
              className="percenttrend-panel-base"
              fontSize={currentValueFontSize}
              lineHeight="1em"
              display="inline"
              verticalAlign="middle"
            >
              {GetIcon(options.icon, GetIconStyle(options))}
            </SpanValue>
            <SpanValue
              className="percenttrend-panel-base"
              fontSize={currentValueFontSize}
              lineHeight="1em"
              display="inline"
              verticalAlign="middle"
            >
              0
            </SpanValue>
          </div>
          <SpanValue
            className="percenttrend-panel-percent"
            color={options.referenceTextColor}
            fontSize={options.baseValueFontSize}
          >
            % 0
          </SpanValue>
          <SpanValue className="percenttrend-panel-ref" fontSize={options.referenceTextFontSize}>
            {options.tolowerText
              ? replaceVariables(options.referenceText).toLowerCase()
              : replaceVariables(options.referenceText)}
          </SpanValue>
        </div>
      </div>
    );
  }

  const currentValueField = currentValueSerie.fields.find((field) => field.name === options.currentValueField);
  const baseValueField = baseValueSerie.fields.find((field) => field.name === options.baseValueField);

  if (!currentValueField || !baseValueField) {
    return (
      <div
        className={cx(
          styles.wrapper,
          css`
            width: ${width}px;
            height: ${height}px;
          `
        )}
      >
        <div className={styles.textBox} style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '10px', whiteSpace: 'nowrap' }}>
            <SpanValue
              className="percenttrend-panel-base"
              fontSize={currentValueFontSize}
              lineHeight="1em"
              display="inline"
              verticalAlign="middle"
            >
              {GetIcon(options.icon, GetIconStyle(options))}
            </SpanValue>
            <SpanValue
              className="percenttrend-panel-base"
              fontSize={currentValueFontSize}
              lineHeight="1em"
              display="inline"
              verticalAlign="middle"
            >
              0
            </SpanValue>
          </div>
          <SpanValue
            className="percenttrend-panel-percent"
            color={options.referenceTextColor}
            fontSize={options.baseValueFontSize}
          >
            % 0
          </SpanValue>
          <SpanValue className="percenttrend-panel-ref" fontSize={options.referenceTextFontSize}>
            {options.tolowerText
              ? replaceVariables(options.referenceText).toLowerCase()
              : replaceVariables(options.referenceText)}
          </SpanValue>
        </div>
      </div>
    );
  }
  if (currentValueField.values.length === 0 || baseValueField.values.length === 0) {
    return (
      <div
        className={cx(
          styles.wrapper,
          css`
            width: ${width}px;
            height: ${height}px;
          `
        )}
      >
        <div className={styles.textBox} style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '10px', whiteSpace: 'nowrap' }}>
            <SpanValue
              className="percenttrend-panel-base"
              fontSize={currentValueFontSize}
              lineHeight="1em"
              display="inline"
              verticalAlign="middle"
            >
              {GetIcon(options.icon, GetIconStyle(options))}
            </SpanValue>
            <SpanValue
              className="percenttrend-panel-base"
              fontSize={currentValueFontSize}
              lineHeight="1em"
              display="inline"
              verticalAlign="middle"
            >
              0
            </SpanValue>
          </div>
          <SpanValue
            className="percenttrend-panel-percent"
            color={options.referenceTextColor}
            fontSize={options.baseValueFontSize}
          >
            % 0
          </SpanValue>
          <SpanValue className="percenttrend-panel-ref" fontSize={options.referenceTextFontSize}>
            {options.tolowerText
              ? replaceVariables(options.referenceText).toLowerCase()
              : replaceVariables(options.referenceText)}
          </SpanValue>
        </div>
      </div>
    );
  }

  const currentValueSum = currentValueField.values.toArray().reduce((sum, current) => sum + current, 0);
  const baseValueSum = baseValueField.values.toArray().reduce((sum, current) => sum + current, 0);

  const display = prepareTrendDisplay(options, theme.visualization, baseValueSum, currentValueSum);

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <div className={styles.textBox} style={{ textAlign: 'center' }}>
        <div style={{ marginBottom: '10px', whiteSpace: 'nowrap' }}>
          <SpanValue
            className="percenttrend-panel-base"
            fontSize={currentValueFontSize}
            lineHeight="1em"
            display="inline"
            verticalAlign="middle"
          >
            {GetIcon(options.icon, GetIconStyle(options))}
          </SpanValue>
          <SpanValue
            className="percenttrend-panel-base"
            fontSize={currentValueFontSize}
            lineHeight="1em"
            display="inline"
            verticalAlign="middle"
          >
            {display.currentValueFormatted}
          </SpanValue>
        </div>
        <SpanValue className="percenttrend-panel-percent" color={display.color} fontSize={options.baseValueFontSize}>
          {display.prefix}
          {display.diffFormatted}
        </SpanValue>
        <SpanValue className="percenttrend-panel-ref" fontSize={options.referenceTextFontSize}>
          {options.tolowerText
            ? replaceVariables(options.referenceText).toLowerCase()
            : replaceVariables(options.referenceText)}
        </SpanValue>
      </div>
    </div>
  );
};

function getPanelStyles() {
  return {
    wrapper: css`
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      padding: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    `,
  };
}
