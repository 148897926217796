import { PanelPlugin } from '@grafana/data';
import { PercentPanelOptions } from './types';
import { PercentPanel } from './PercentPanel';
import { TeamscxCallIcons } from 'TeamcxIcons';

// .useFieldConfig()
export const plugin = new PanelPlugin<PercentPanelOptions>(PercentPanel).setPanelOptions((builder) => {
  return builder
    .addFieldNamePicker({
      path: 'currentValueField',
      name: 'Real-time Value Field',
      defaultValue: '',
    })
    .addFieldNamePicker({
      path: 'baseValueField',
      name: 'Base Value Field',
      defaultValue: '',
    })
    .addUnitPicker({
      path: 'unit',
      name: 'Unit',
      defaultValue: '',
    })
    .addNumberInput({
      path: 'currentValueDecimals',
      name: 'Nr. of decimals for real-time value',
      description: 'For the real-time value number',
      defaultValue: undefined,
    })
    .addBooleanSwitch({
      path: 'positiveIsGood',
      name: 'Colorize positive trend in green',
      defaultValue: true,
    })
    .addTextInput({
      path: 'referenceText',
      name: 'Description of which values are compared',
      defaultValue: 'vs previous 7 days',
    })
    .addNumberInput({
      path: 'diffDecimals',
      name: 'Nr. of decimals for different value display',
      description: 'Displayed for the different value (blank for auto)',
      defaultValue: 0,
    })
    .addTextInput({
      path: 'currentValueFontSize',
      name: 'Font size for real-time value',
      description: 'Either in percentage or pixel',
      defaultValue: '80%',
    })
    .addTextInput({
      path: 'baseValueFontSize',
      name: 'Font size for base display',
      description: 'Either in percentage or pixel',
      defaultValue: '16px',
    })
    .addTextInput({
      path: 'referenceTextFontSize',
      name: 'Font size for reference text',
      description: 'Either in percentage or pixel',
      defaultValue: '12px',
    })
    .addBooleanSwitch({
      path: 'displayPercent',
      name: 'Display percentage',
      defaultValue: true,
    })
    .addSelect({
      path: 'icon',
      name: 'Icon',           
      settings: {
        options: [          
          {
            value: TeamscxCallIcons.Total,
            label: 'Total',   
          },                      
          {
            value: TeamscxCallIcons.AnsweredIn,
            label: 'Answered In',   
          }, 
          {
            value: TeamscxCallIcons.Abandoned,
            label: 'Abandoned',
          },  
          {
            value: TeamscxCallIcons.Missed,
            label: 'Missed',   
          },
          {
            value: TeamscxCallIcons.Answered,
            label: 'Answered',   
          },
          {
            value: TeamscxCallIcons.Handle,
            label: 'Handle',   
          },
          {
            value: TeamscxCallIcons.Waiting,
            label: 'Waiting',   
          },
          {
            value: TeamscxCallIcons.Longest,
            label: 'Longest',   
          },
        ],
      }
    })
    .addTextInput({
      path: 'iconSize',
      name: 'Icon size',
      description: 'em, px,...',
      defaultValue: '1em',
    })
    .addBooleanSwitch({
      path: 'tolowerText',
      name: 'Tolower reference text?',
      defaultValue: false,
    })
    .addColorPicker({
      path: 'referenceTextColor',
      name: 'Color for reference text',
      description: 'Choose color for text',
      defaultValue: '',
    });
});
